.jf-searchtypetags {
  margin: 15px;
}
// TABS CONTENT CSS --START

.jf-media-tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.jf-videos {
  flex-grow: 1;
  overflow-y: auto;
}

.jf-tagsbox {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 15px;
  .jf-hashtag {
    display: flex;
    border-radius: 5px;
    background-color: colors(jf-light2);
    margin-right: 10px;
    padding: 8px 12px;
    font: 14px/22px fonts(jf-fontRegular);
    color: colors(jf-black);
    &.jf-active{
      background-color: colors(jf-primary);
      color: colors(jf-white);
    }
  }
}

.jf-create-result{
  max-height: 295px;
  overflow: auto;
  margin: 115px 15px  5px  15px ;
  position: absolute;
  z-index: 7;
  background: colors(jf-white);
  width: calc(100% - 30px);
  border: 1px solid colors(jf-light2);
  border-radius: 5px;
  li {
    padding: 10px 10px 5px 10px;
    border: 1px solid white;
    &:hover {
      background-color: colors(jf-light2);
    }
    &.active {
      background-color: colors(jf-light2);
    }
    &>a, &>div {
      display: flex;
      column-gap: 10px;
      align-items: center;
      min-height: 40px;
      .jf-fullname {
        font: 18px/22px fonts(jf-fontRegular);
        color: colors(jf-black2);
      }
      .jf-username {
        font: 14px/22px fonts(jf-fontRegular);
        color: colors(jf-gray);
        display: contents;
      }
    }
  }
}
.jf-creatorslist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
  grid-gap: 6px;
  flex-grow: 1;
  overflow-y: auto;
  width: calc(100% - 30px);
  margin-top: 15px;
  padding: 0 15px;
  .jf-creatorsBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .jf-username {
      width: 100%;
      justify-content: center;
      color: colors(jf-black);
      font: 18px/20px fonts(jf-fontRegular);
      margin-top: 8px;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}

.jf-filters-list {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 30px);
  margin: 15px;
  .jf-filtersBox {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 5px;
    color: colors(jf-black);
    background-color: colors(jf-light2);
    margin-right: 10px;
    font: 14px/22px fonts(jf-fontRegular);
    transition: $transitions;
    cursor: pointer;
    &.jf-active {
      background-color: colors(jf-primary);
      color: colors(jf-white);
    }
  }
}