.jf-popup-report {
  &-title {
    font: 24px/32px fonts(jf-fontMedium);
    text-align: center;
    margin-left: 45px;
    padding-top: 12px;
  }
  &-content {
    font: 18px/24px fonts(jf-fontRegular);
    margin: 20px;
    .jf-text-field {
      &-label {
        font-family: fonts(jf-fontMedium);
      }
      &-input {
        textarea {
          padding: 17px 20px;
          font: 20px/27px fonts(jf-fontRegular);
        }

        .jf-text-field-select {
          padding: 15px 20px;
          font: 20px/27px fonts(jf-fontRegular);
          &-items{
            margin-top: 65px;
          }
        }
        .jf-text-field-placeholder {
          font: 20px/27px fonts(jf-fontRegular);
        }
        .jf-text-field-view-btn{
          top: 0;
        }
      }
    }
  }
  .jf-basic-btn {
    width: 134px;
    padding: unset;
  }
  .jf-report-btn {
    background-color: colors(jf-light2);
    color: colors(jf-black2);
  }
  .jf-report-btns {
    float: right;
    display: inline-flex;
    margin-right: 20px;
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .jf-popup-report {
    margin-left: calc(50% - 435px);
  }
}