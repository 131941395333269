.jf-search-text {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 30px);
  margin: 0 auto;
  height: 45px;
  min-height: 45px;
  border-radius: 5px;
  background-color: colors(jf-light2);
  position: relative;
  .jf-search-text-input {
    width: calc(100% - 60px);
    border: none;
    outline: none;
    font: 16px/22px fonts(jf-fontRegular);
    background-color: transparent;
    padding: 0 15px;
  }
  .jf-placeholder {
    font: 16px/22px fonts(jf-fontRegular);
    color: colors(jf-placeholder2);
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 10px;
  }

  &-right {
    margin: auto 10px auto auto;
  }
}