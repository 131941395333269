.jf-thumbnail-up {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .jf-thumbnail-up-btn {
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
    path, circle {
      fill: colors(jf-white);
    }
  }
  &.type-round {
    border-radius: 50%;
    background-color: rgba(colors(jf-primary), 0.05);
    .jf-thumbnail-up-btn {
      border-radius: 50%;
      path, circle {
        fill: colors(jf-primary);
      }
    }
    img {
      border-radius: 50%;
    }
  }
  &.type-square {
    .jf-thumbnail-up-btn {
      border: rgba(colors(jf-primary), 0.2) 2px solid;
      border-radius: 5px;
      path, circle {
        fill: rgba(colors(jf-primary), 0.2);
      }
    }
  }
  &.has-image {
    &.type-square {
      .jf-thumbnail-up-btn {
        border-radius: 0;
      }
    }
    .jf-thumbnail-up-btn {
      background-color: rgba(colors(jf-black2), 0.6);
      border: none;
      path, circle {
        fill: colors(jf-white);
      }
    }
  }
}
