.jf-sound {
  &-content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .jf-basic-btn.white-btn {
      margin: 20px  auto ;
      width: 250px;
    }
    .jf-search-text {
      margin-top: 15px;
      .jf-placeholder {
        justify-content: start;
        padding-left: 10px;
      }
    }
    .jf-soundlist {
      flex-direction: column;
      display: flex;
      margin-top: 15px;
      overflow: auto;
      flex-grow: 1;
      &-li {
        display: flex;
        flex-direction: row;
        &:hover {
          background-color: rgba(colors(jf-primary), 0.05);
        }
        &.selected {
          background-color: rgba(colors(jf-primary), 0.05);
          .jf-soundSelect {
            .jf-selecticon {
              svg path {
                fill: colors(jf-primary);
              }
            }
          }
        }
        &-a {
          display: flex;
          flex-direction: row;
          border-bottom: solid 1px rgba(colors(jf-black2), 0.05);
          padding: 0 20px;
          height: 65px;
          justify-content: space-between;
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          .jf-soundDetail {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 0;
            .jf-playIcon {
              width: 30px;
              height: 30px;
              align-items: center;
              display: flex;
              justify-content: center;
              margin-right: 10px;
            }
            .jf-sound-title {
              color: colors(jf-black);
              font: 18px/22px fonts(jf-fontMedium);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .jf-soundSelect {
            display: flex;
            flex-direction: row;
            .jf-selecticon {
              display: flex;
              flex-flow: row wrap;
              width: 30px;
              height: 30px;
              align-items: center;
              justify-content: center;
              svg {
                width: 20px;
                height: 20px;
              }
              svg path {
                fill: colors(jf-light);
              }
            }
          }
        }
      }
    }
  }
}
