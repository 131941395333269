.jf-popup-comment {
  width:475px;
  height: 60%;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    .title {
      text-align: center;
      flex-grow: 1;
      font: 18px/21px fonts(jf-fontBold);
      margin-left: 20px;
    }
  }
  &-list {
    flex-grow: 1;
    padding: 10px 5px 10px 20px;
    .jf-comment-item {
      display: flex;
      flex-direction: row;
      padding-right: 5px;
      margin-bottom: 20px;
      &-body {
        font: 16px fonts(jf-fontBold);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 15px;
        &>div:nth-child(2) {
          font: 16px fonts(jf-fontRegular);
        }
        &>div:last-child {
          margin-top: 5px;
          font: 14px fonts(jf-fontRegular);
          color: colors(jf-placeholder2);
        }
      }
      .jf-favorite-btn {
        margin-right: 15px;
      }
    }
  }
  &-footer {
    &.jf-write-box {
      display: flex;
      align-items: center;
      background-color: colors(jf-primary);
      padding: 23px 20px;
      flex-direction: row;
      .jf-write-box-input {
        flex-grow: 1;
        display: flex;
        outline: none;
        border: none;
        height: 24px;
        background: transparent;
        font: 18px/24px fonts(jf-fontRegular);
        color: colors(jf-white);
        &::placeholder {
          color: colors(jf-white);
          opacity: 0.5;
        }
        &::-webkit-input-placeholder {
          color: colors(jf-white);
          opacity: 0.5;
        }

        &.alert {
          color: colors(jf-white);
          opacity: 0.5;
          text-align: center;
          display: revert;
        }
      }
    }
  }
}

@media only screen and (max-width: $width-to-desktop) {
  .jf-popup-comment {
    width: 100%;
  }
}