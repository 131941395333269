.jf-earnings-page {
  padding: 0;
  display: flex;
  flex-direction: column;
  button, .jf-select-tab-li {
    font-size: 18px;
  }
  .jf-earnings {
    &-paid {
      display: flex;
      flex-direction: column;
      background-color: colors(jf-light2);
      padding: 15px 20px;
      font: 20px/30px fonts(jf-fontRegular);
      div:nth-child(2) {
        height: 54px;
        span{
          font: 54px/54px fonts(jf-fontBold);
        }
        font: 20px/54px fonts(jf-fontBold);
      }
      div:nth-child(3) {
        color: colors(jf-gray);
      }
    }
    &-period {
      display: flex;
      flex-direction: row;
      padding: 23px 20px;
      div:nth-child(1) {
        font: 20px/22px fonts(jf-fontRegular);
        flex-grow: 1;
        color: colors(jf-gray);
      }
      div:nth-child(2) {
        font: 20px/22px fonts(jf-fontMedium);
      }
    }
    &-transaction {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(colors(jf-gray), 0.1);
      justify-content: center;
      font: 16px/20px fonts(jf-fontRegular);
      align-items: center;
      div:nth-child(2) {
        font: 16px/20px fonts(jf-fontMedium);
        margin-left: 10px;
        flex-grow: 1;
      }
      div:nth-child(3) {
        width: 100px;
        color: colors(jf-gray);
      }
      div:nth-child(4) {
        width: 60px;
        color: colors(jf-primary);
        font: 16px/20px fonts(jf-fontBold);
      }
      div:nth-child(5) {
        width: 60px;
        color: colors(jf-gray);
      }
    }
    &-payout {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(colors(jf-gray), 0.1);
      justify-content: center;
      font: 16px/20px fonts(jf-fontRegular);
      color: colors(jf-gray);
      align-items: center;
      div:nth-child(1) {
        width: 40%;
      }
      div:nth-child(2) {
        width: 30%;
        color: colors(jf-primary);
        font: 16px/20px fonts(jf-fontBold);
      }
      div:nth-child(3) {
        width: 30%;
        text-align: right;
      }
    }
  }
}