.jf-mass-message {
  .jf-search-text {
    margin-top: 15px;

    .jf-placeholder {
      justify-content: start;
      padding-left: 10px;
    }
  }

  .jf-select-tab {
    margin: 15px;
  }

  .jf-user-item {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-bottom: 1px solid rgba(colors(jf-black), 0.1);
    background-color: rgba(colors(jf-primary), 0.05);
    align-items: center;
    &-body {
      font: 18px fonts(jf-fontMedium);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 15px;
      justify-content: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & > div:nth-child(2) {
        margin-top: 5px;
        color: rgba(colors(jf-gray), 0.64);
      }
    }

    & > div:last-child {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      color: rgba(colors(jf-black2), 0.5);
      font: 14px fonts(jf-fontRegular);

      .jf-unread {
        font: 12px/24px fonts(jf-fontRegular);
        color: colors(jf-white);
        background-color: colors(jf-primary);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        text-align: center;
        margin-top: 15px;
        align-self: flex-end;
      }
    }
    .jf-check-circle-btn {
      height: 26.5px;
    }
  }

  .jf-check-circle-btn {
    border: 1px solid colors(jf-primary);
    background: transparent;
    padding: 0;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  .jf-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 2;
  }
}