.jf-avatar {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  &-picture {
    position: relative;
    overflow: hidden;
    border: none;
    z-index: 0;
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 94%;
    height: 94%;
    margin: auto;
    img {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    span {
      margin: auto;
      color: colors(jf-white);
      font: 20px fonts(jf-fontRegular);
    }
  }
  &-online-icon {
    position: absolute;
    background-color: colors(jf-online);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    right: 0;
    z-index: 1;
    top: 10%;
  }
  .jf-live-label {
    position: absolute;
    text-align: center;
    width: 35px;
    bottom: -10px;
    margin: 0 calc(50% - 17.5px);
    background-color: colors(jf-live);
    font: 14px/20px fonts(jf-fontRegular);
    border-radius: 3px;
    color: colors(jf-white);
  }
  &.jf-live {
    border: 3px solid colors(jf-live);
    border-radius: 50%;
  }
}