.jf-delete-page {
  &-note {
    background-color: colors(jf-light);
    padding: 20px;
    font: 16px/22px fonts(jf-fontRegular);
    color: colors(jf-gray);
    .black {
      color: colors(jf-black2)
    }
    .warning {
      padding-top: 15px;
      padding-bottom: 10px;
      color: colors(jf-like);
      font: 16px/22px fonts(jf-fontMedium);
    }
  }
  &-form {
    margin: 5px 20px 0 20px;

    .jf-account-save-btn {
      margin-top: 20px;
    }
  }
  &-download {
    color: colors(jf-primary);
    font: 16px/22px fonts(jf-fontMedium);
  }
}

.jf-email-page {
  .jf-account-save-btn {
    margin-top: 20px;
  }
}

.jf-my-meida-page {
  display: flex;
  flex-direction: column;
  .jf-media-overflow {
    flex-grow: 1;
    overflow-y: auto;
  }
}