.jf-tag-user-popup {
  width: calc(100% - 40px);
  position: absolute;
  bottom: 70px;
  left: 0;
  z-index: 9;
  border-radius: 20px 20px 0 0;
  border-top: solid 2px colors(jf-border);
  background-color: white;
  padding: 20px 20px 3px 20px;
  &-list {
    font: 18px/24px fonts(jf-fontBold);
    height: 340px;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    &-item {
      display: flex;
      margin-bottom: 20px;
      &-center {
        margin-left: 15px;
      }
      &-center-body {
        font: 16px/21px fonts(jf-fontRegular);
        color: colors(jf-black3);
      }
    }
  }
}