.jf-text-clipboard {
  display: flex;
  flex-flow: row wrap;
  &-text {
    font: 18px/24px fonts(jf-fontRegular);
    color: colors(jf-primary);
    text-overflow: clip;
    overflow: hidden;
  }
  &-icon {
    padding: 0;
    border: none;
    height: 18px;
    width: 14px;
    background: none;
    margin-top: 4px;
    margin-left: 10px;
    cursor: pointer;
    &::selection {
      path {
        fill: colors(jf-primary);
      }
    }
  }
}