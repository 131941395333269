.jf-edit-profile {
  &-form {
    padding: 20px;
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    overflow-y: auto;
    .jf-thumbnail-up {
      margin: auto;
    }
    &-note {
      margin-top: 18px;
      text-align: center;
      color: colors(jf-black2);
      font: 18px/26px fonts(jf-fontRegular);
    }
    .jf-text-field {
      margin-top: 13px;
    }
    .jf-text-clipboard {
      margin-top: 8px;
    }

    .pass-note {
      display: flex;
      align-items: center;
      color: rgba(colors(jf-black), 0.5);
      font: 16px/21px fonts(jf-fontRegular);
      margin-top: 10px;
      svg {
        margin-right: 10px;
      }
      &.ok {
        color: colors(jf-online);
      }
      &.err {
        color: colors(jf-error);
      }
    }

    .jf-choices {
      display: flex;
      flex-flow: row;
      margin: 9px 0 13px 0;
      border-radius: 5px;
      justify-content: center;

      &-label {
        font: 18px/24px fonts(jf-fontMedium);
      }

      &-li {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 25%;
        max-width: 33.33%;
        height: 45px;
        border-radius: 5px;
        color: colors(jf-black);
        font: 18px/22px fonts(jf-fontMedium);
        cursor: pointer;
        transition: $transitions;
        flex-grow: 1;
        background-color: colors(jf-light2);

        &.jf-active {
          background-color: colors(jf-primary);
          color: colors(jf-white);
        }
      }
    }
  }
}
.jf-setting-basic-form {
  flex-grow: 1;
  form>div,form .jf-text-field {
    margin-top: 8px;
  }
}
.jf-password-form {
  form > div, form .jf-text-field {
    margin-top: 8px;
  }
}
.jf-save-btn {
  border-radius: 0 !important;
  position: sticky;
  bottom: 0;
  left: 0;
}
