.jf-splashHeader {
  display: flex;
  height: 110px;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  .jf-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // font: 62px/62px $jf-vijaya-bold;
    font: 62px/62px fonts(jf-vijaya-bold);
    color: colors(jf-primary);
    margin-top: -20px;
    margin-left: 10px;
    .jf-logo-span {
      color: colors(jf-secondary);
    }
  }
}
.jf-home-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-size: 100% auto;
  background-color: colors(jf-primary);
  background-image: modelImage("modelImage", "model-1");
  background-repeat: no-repeat;
  height: calc(100% - 110px);
  .jf-content {
    width: calc(100% - 60px);
    padding: 0 30px;
    min-height: 177px;
    background-color: colors(jf-primary);
    .jf-social {
      background-color: colors(jf-white);
      border-radius: 10px;
      box-shadow: 0 0 40px rgba(colors(jf-black), 0.2);
      margin: -117px 0 20px;
      overflow: hidden;
      &-wrap {
        padding: 24px 24px 18px;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
      }
      &-title {
        font: 26px/31px fonts(jf-fontBold);
        color: colors(jf-primary);
        margin-bottom: 15px;
        margin-top: 0;
      }
      &-color {
        color: colors(jf-secondary);
      }
      &-desc {
        text-align: center;
        margin: 0;
        font: 20px/26px fonts(jf-fontRegular);
      }
      .js-actions {
        display: flex;
        width: 100%;
        margin-top: 3px;
        .jf-btn {
          width: 100%;
        }
      }
    }
    .jf-btn-login {
      border-radius: 5px;
      background-color: rgba(colors(jf-white), 0.15);
    }
    .jf-copyright {
      text-align: center;
      color: colors(jf-white);
      font: 18px/21px fonts(jf-fontRegular);
    }
  }
}