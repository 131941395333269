.jf-popup-camera_options {
  &-item {
    font: 20px/24px fonts(jf-fontBold);
    color: colors(jf-gray);
    text-align: center;
    padding: 20px 0;
    &:hover{
      background-color: colors(jf-light2);
    }
  }
  .jf-popup-contain {
    padding: 0;
  }
  &-item:nth-child(2) {
    border-bottom: 2px solid rgba(colors(jf-black), 0.1);
    border-radius: 20px 20px 0 0;
  }
  &-item:last-child {
    border-radius: 0 0 20px 20px;
  }
  .jf-popup-close-btn {
    display: none;
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .jf-popup-camera_options {
    width: 278px;
    margin-right: calc(207px - 139px);
  }
}