.jf-bottom-popup {
  width: 100%;
  max-width: 475px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  border-radius: 20px 20px 0 0;
  border-top: solid 2px colors(jf-border);
  background-color: white;
  display: flex;
  flex-direction: column;

  &-contain-list {
    font: 18px fonts(jf-fontBold);
    padding: 15px 20px 0 20px;

    .jf-bottom-popup-header {
      text-align: left;
      margin-bottom: 9px;
    }

    .jf-bottom-popup-body .jf-bottom-popup-item {
      display: flex;
      width: calc(100% - 2px);
      height: 70px;
      margin-bottom: 10px;
      border: solid 1px colors(jf-border5);
      border-radius: 5px;
      align-items: center;

      .jf-bottom-popup-item-icon {
        display: flex;
        margin-left: 20px;
        height: 24px;
        width: 24px;

        svg {
          height: 100% !important;
          width: 100% !important;
        }
      }

      .jf-bottom-popup-item-text {
        margin-left: 26px;
        display: flex;
      }
    }
  }

  &-contain-grid {
    font: 16px/21px fonts(jf-fontRegular);
    padding: 13px 14px 0 14px;

    .jf-bottom-popup-header {
      font: 16px fonts(jf-fontBold);
      text-align: center;
      margin-bottom: 20px;
    }

    .jf-text-clipboard {
      margin: 20px 6px;
      border: colors(jf-inputborder) 2px solid;
      padding: 20px 15px;
      border-radius: 5px;

      .jf-text-clipboard-text {
        color: colors(jf-black3);
        font: 20px/26px fonts(jf-fontRegular);
      }
    }

    .jf-bottom-popup-body {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      .jf-bottom-popup-item {
        float: left;
        height: 84px;
        cursor: pointer;

        .jf-bottom-popup-item-icon {
          margin: auto;
          height: 60px;
          width: 60px;

          svg {
            height: 100% !important;
            width: 100% !important;
          }
        }

        .jf-bottom-popup-item-text {
          text-align: center;
          margin-top: 3px;
        }
      }
    }
  }

  &-cancel {
    border-radius: 0;
    height: 70px;
  }

  &.show {
    visibility: visible;
    max-height: 650px;
    opacity: 1;
    transition: visibility 0s, max-height 1s, opacity 0.5s linear;
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: visibility 0s, max-height 1s, opacity 0.5s linear;
  }

  &.jf-popup-cookie_policy {
    max-width: 900px;
  }

  &.ios {
    max-width: 80%;
    margin-bottom: 25px;
    border-radius: 10px;
  }

  &.ios:after, &.ios:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &.ios:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 20px;
    margin-left: -20px;
  }

  &.ios:before {
    border-color: rgba(220, 220, 221, 0);
    border-top-color: #dcdcdd;
    border-width: 23px;
    margin-left: -23px;
  }

  &-ios-install-app {
    display: flex;
    align-items: flex-end;
    padding: 30px 35px;
  }

  &-cookie-policy {
    display: flex;
    align-items: flex-end;
    padding: 30px 35px;

    .jf-bottom-popup-cookie-policy-container {
      flex: 1;
      margin: 0px 20px;

      .jf-bottom-popup-header {
        font-size: 38px;
        font-family: fonts(jf-fontBold);
        margin-bottom: 20px;
      }

      .jf-bottom-popup-header-close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .jf-bottom-popup-body {
        font-size: 20px;
        font-family: fonts(jf-fontRegular);;
        color: rgba(colors(jf-black), 0.5);
      }
    }

    .jf-bottom-popup-cookie-actions {
      display: flex;

      .cookie-decline {
        background-color: #F5F5F7;
        color: black;
        margin-right: 10px;
      }
    }


    @media only screen and (max-width: $width-to-desktop) {
      flex-direction: column;
      align-items: center;

      svg {
        width: 100px;
        height: 100px;
      }

      .jf-bottom-popup-cookie-policy-container {
        margin: 20px 0px;

        .jf-bottom-popup-header {
          font-size: 28px;
          text-align: center;
        }

        .jf-bottom-popup-body {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }

  &-ios-install-app {
    display: flex;
    align-items: flex-end;
    padding: 0 25px;

    .jf-bottom-popup-cookie-policy-container {
      flex: 1;
      margin: 0px 20px;
      display: flex;
      flex-direction: column;

      svg{
        align-self: center;
      }

      .jf-bottom-popup-header {
        font-size: 38px;
        font-family: fonts(jf-fontBold);
        margin-bottom: 20px;
      }

      .jf-bottom-popup-header-close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        div {
          border-radius: 50%;
          background-color: #f2f2ff;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        svg {
          width: 20px;
          height: 20px;
          color: #8a88fb;
        }
      }

      .jf-bottom-popup-body {
        font-size: 20px;
        font-family: fonts(jf-fontRegular);
        color: rgba(colors(jf-black), 0.5);
        div{
          line-height: 30px;
        }
        svg{
          width: 30px;
          height: 30px;
        }
      }
    }

    .jf-bottom-popup-cookie-actions {
      display: flex;

      .cookie-decline {
        background-color: #F5F5F7;
        color: black;
        margin-right: 10px;
      }
    }


    @media only screen and (max-width: $width-to-desktop) {
      flex-direction: column;
      align-items: center;

      svg {
        width: 100px;
        height: 100px;
      }

      .jf-bottom-popup-cookie-policy-container {
        margin: 20px 0px;

        .jf-bottom-popup-header {
          font-size: 28px;
          text-align: center;
        }

        .jf-bottom-popup-body {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
}

.jf-bottom-popup-cover {
  position: fixed;
  background-color: colors(jf-black2);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8;

  &.hide {
    display: none;
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .jf-bottom-popup {
    margin: 0 0 0 calc(50% - 444.5px);
  }
}

@import "comment";