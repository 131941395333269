.jf-singup-content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  min-height: 100vh;
  .jf-header {
    width: 100%;
  }
  .jf-singup-body {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 0 40px;
    flex-grow: 1;
    .jf-title {
      margin: 35px 0 13px;
      font: 32px/42px fonts(jf-fontBold);
      color: colors(jf-black2);
      display: flex;
      flex-flow: row wrap;
      width: 100%;
    }

    .jf-input-boxs {
      .jf-input-name,
      .jf-input-username {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        height: 70px;
        border-radius: 5px;
        border: solid 2px colors(jf-inputborder);
        input {
          background-color: colors(jf-white) !important;
          background: transparent;
          border: none;
          outline: none;
          width: 100%;
          font: 18px/21px fonts(jf-fontRegular);
          padding: 0 20px;
        }
      }
      .jf-input-username {
        margin: 15px 0;
      }
      .jf-input-pass {
        position: relative;
        .jf-password-view {
          position: absolute;
          right: 21px;
          top: 23px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
        }
      }
    }
    .jf-btn-ul {
      margin-top: 15px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      &-li {
        width: 100%;
        justify-content: center;
        display: flex;
        font: 20px/24px fonts(jf-fontRegular);
        color: colors(jf-black3);
        text-transform: uppercase;
        &:last-child{
          margin: 0 0 42px 0;
        }
      }
    }
  }
  .jf-terms {
    display: flex;
    justify-content: center;
    align-items: center;
    font: 18px/21px fonts(jf-fontRegular);
    padding: 0 50px;
    flex-flow: row wrap;
    color: colors(jf-black3);
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    &-link {
      color: colors(jf-secondary);
    }
  }
}
