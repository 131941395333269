.jf-search {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(colors(jf-black2), 0.05);
  max-height: 60px;
  position: relative;
  &-icon {
    display: flex;
    flex-flow: column;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin: 0 13px 0 12px;
  }
  .jf-input-search {
    display: flex;
    outline: none;
    border: none;
    background: transparent;
    color: colors(jf-placeholder2);
    font: 16px/22px fonts(jf-fontRegular);
  }
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
.jf-closeround {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  border: none;
  background-color: transparent;
  right: 15px;
}
