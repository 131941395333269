.jf-discover-list {
  max-width: 100%;
  display: grid;
  &-header {
    display: flow-root;
    &-title {
      display: flex;
      float: left;
      font: 18px/22px fonts(jf-fontBold);
    }
    &-count {
      border: none;
      display: flex;
      float: right;
      font: 16px/22px fonts(jf-fontBold);
      background-color: colors(jf-border5);
      padding: 2px 7px;
      border-radius: 5px;
      svg {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 4px;
      }
    }
  }
  &-body {
    margin-top: 27px;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      width: 140px;
      height: 180px;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      img, video {
        width: 100%;
        height: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
      &-detail {
        position: absolute;
        z-index: 1;
        bottom: 0;
        padding: 5px;
        .jf-avatar {
          float: left;
          margin-top: 3px;
          margin-right: 5px;
        }
        &-name {
          font: 12px/16px fonts(jf-fontBold);
          color: colors(jf-white);
        }
        &-id {
          font: 12px/16px fonts(jf-fontRegular);
          color: colors(jf-white);
        }
      }
      &.wrap {
        height: auto;
        img {
          width: 100%;
          height: 180px;
        }
        .jf-discover-list-body-item-detail {
          position: unset;
          &-name {
            font: 16px/21px fonts(jf-fontBold);
            color: colors(jf-black2);
          }
          &-id {
            font: 16px/21px fonts(jf-fontRegular);
            color: colors(jf-black3);
          }
        }
      }
    }
  }
}