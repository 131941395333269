.jf-type-message {
  display: flex;
  box-shadow: 0 0 40px rgba(94, 92, 154, 0.1);
  align-items: center;
  position: relative;
  padding: 20px;
  &-emoji-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    margin-right: 15px;
  }
  &-input {
    width: 100%;
    border-radius: 24px;
    border: #DEDEEA 1px solid;
    input {
      padding: 12px;
      width: 100%;
      height: 46px;
      display: flex;
      outline: none;
      border: none;
      background: transparent;
      font: 16px/20px fonts(jf-fontRegular);
    }
  }
  &-send-icon {
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: colors(jf-secondary);
    border-radius: 50%;
    right: 25px;
    border: none;
    padding: 8px;
  }
}