.jf-input-boxs {
  display: flex;
  flex-direction: column;
  .jf-text-field {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.jf-forpass {
  font: 18px/24px fonts(jf-fontRegular);
  color: colors(jf-forgotpass);
  margin: 14px 0 19px;
  .jf-gethelp {
    color: colors(jf-secondary);
  }
}
.jf-btn-ul {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  &-li {
    width: 100%;
    justify-content: center;
    display: flex;
    color: colors(jf-black3);
    text-transform: uppercase;
  }
}

.jf-sign-content {
  width: 100%;
  min-height: 100vh;
}
