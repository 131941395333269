.jf-text-field {
  position: relative;
  display: flex;
  flex-direction: column;
  &-label {
    font: 18px/24px fonts(jf-fontRegular);
    color: colors(jf-black);
    margin-bottom: 10px;
  }
  &-input {
    display: flex;
    flex-direction: row;
    background-color: colors(jf-light2);
    align-items: center;
    border-radius: 5px;
    .jf-money-text {
      font: 18px/26px fonts(jf-fontRegular);
      padding: 0 20px;
      border-right: 1px solid rgba(colors(jf-border6),0.1);
    }
  }
  min-height: 50px;
  input, textarea, .jf-text-field-select {
    min-height: 46px;
    padding: 0 20px;
    background-color: colors(jf-light2) !important;
    color: colors(jf-black2);
    font: 18px/26px fonts(jf-fontRegular);
    border: 2px solid colors(jf-light2);
    border-radius: 5px;
    flex-grow: 1;
    width: 100%;
  }
  .jf-text-field-select {
    padding: 10px 20px;
    min-height: auto;
  }
  .jf-text-field-select-items{
    max-height: 295px;
    overflow: auto;
    margin-top: 51px;
    position: absolute;
    z-index: 7;
    background: white;
    width: calc(100% - 2px);
    border: 1px solid colors(jf-light2);
    border-radius: 5px;
    align-self: start;
    li {
      padding: 10px 20px 5px 20px;
      border: 1px solid white;
      &:hover {
        background-color: colors(jf-light2);
      }
      &.active {
        background-color: colors(jf-light2);
      }
      &>div {
        display: flex;
        column-gap: 10px;
        align-items: center;
        min-height: 40px;
      }
    }
  }
  .jf-text-field-placeholder {
    color: colors(jf-placeholder2);
    font: 18px/26px fonts(jf-fontRegular);
  }
  &-view-btn {
    width: 30px;
    min-height: 30px;
    border: none;
    background-color: transparent;
    margin-right: 15px;

    &-ex {
      margin-right: 10px;
      padding: 0;
      border: none;
      background: none;
    }
  }
  &.has-label {
    .jf-text-field-view-btn {
      top: 42px;
    }
  }
  &-msg {
    margin-top: 13px;
    font: 18px/26px fonts(jf-fontRegular);
    &.error {
      color: colors(jf-error);

      a {
        color: colors(jf-secondary);
      }
    }
    &.success {
      color: forestgreen;
    }
  }
  &.has-error {
    .jf-text-field-input {
      border: colors(jf-error) 2px solid;
      border-radius: 5px;
    }
    .jf-text-field-msg {
      color: colors(jf-error);

      &.success {
        color: forestgreen;
      }
    }
  }
}

.jf-radio {
  &.container {
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border: 1px solid colors(jf-gray);
    border-radius: 50%;
    margin-top: -5px;
    cursor: pointer;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &.container input:checked ~ .checkmark:after {
    display: block;
  }

  &.container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: colors(jf-gray);
  }
}
