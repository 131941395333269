.jf-popup {
  width: 100%;
  position: fixed;
  max-width: 450px;
  height: fit-content;
  z-index: 9;
  border-radius: 20px;
  border: solid 2px colors(jf-border);
  background-color: white;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  &.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
  }
  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  &-contain{
    .jf-popup-close-btn {
      float: right;
      margin-right: 15px;
      margin-top: 15px;
    }
  }
  .jf-avatar-picture {
    height: 100%;
    width: 100%;
  }
}

.jf-popup-cover {
  position: fixed;
  background-color: colors(jf-black2);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8;

  &.hide {
    display: none;
  }
}

.jf-popup-alert {
  &-title {
    font: 24px/29px fonts(jf-fontBold);
    text-align: center;
    margin-left: 45px;
    padding-top: 17px;
  }
  &-content {
    padding: 0 20px 20px 20px;
    .outline-btn {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: $width-to-desktop) {
  .jf-popup {
    top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@import "subscription";
@import "tips";
@import "report-block";
@import "report";
@import "camera_options";