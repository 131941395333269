.jf-user-profile-page {
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-gutter: stable;
  &-top-menu {
    .jf-right {
      cursor: pointer;
      display: contents;
      &>button {
        width: 40px;
      }
      svg:nth-child(2) {
        margin-right: 10px;
      }
      svg path:nth-child(2) {
        fill: colors(jf-primary);
        stroke: colors(jf-primary);
      }
    }
  }
  .jf-media-tabs {
    flex-grow: 1;
  }
}

.jf-user-profile-detail {
  justify-items: center;
  display: grid;
  padding: 20px 20px 0 20px;
  &-report {
    margin-top: 14px;
    display: flex;
    &>button {
      text-align: center;
      text-transform: capitalize;
      cursor: pointer;
      margin-right: 10px;
      svg {
        margin-top: 2px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &-icon-btn {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: rgba(124, 121, 250, 0.05);
      transition: all 0.3s ease;
      border: none;
      &:hover {
        background-color: colors(jf-light);
      }
    }
    .jf-basic-btn {
      border-radius: 30px;
      padding-left: 27px !important;
      padding-right: 27px !important;
    }
  }
  &-title-1 {
    margin-top: 12px;
    color: colors(jf-black2);
    font: 26px/35px fonts(jf-fontBold);
  }
  &-title-2 {
    color: colors(jf-gray);
    font: 18px/21px fonts(jf-fontRegular);
  }
  &-content {
    margin-top: 18px;
    width: 100%;
    color: colors(jf-gray);
    font: 18px/24px fonts(jf-fontRegular);
    span {
      color: colors(jf-black2);
    }
    .medium {
      font-family: fonts(jf-fontMedium);
    }
  }
}