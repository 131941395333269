.jf-media-msg {
  max-width: 372px;
  background-color: colors(jf-frame);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;

  .jf-select-box {
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: colors(jf-white);
    border: 1px solid rgba(colors(jf-border6), 0.2);
    width: fit-content;
    height: $thumbnail-height-portrait;
    align-self: end;
    &.landscape {
      width: 100%;
      height: $thumbnail-height-landscape;
    }
    &-content {
      text-align: center;
      cursor: pointer;
    }
    .jf-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
  img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }
  textarea {
    font: 16px/22px fonts(jf-fontRegular);
    height: 117px;
    border: 1px solid rgba(colors(jf-border6), 0.2);
    border-radius: 5px;
    padding: 15px;
    background-color: white !important;
  }
  .jf-text-field-input {
    .jf-money-text {
      color: colors(jf-gray);
      font: 16px/22px fonts(jf-fontRegular);
      padding: 0 15px;
    }
    background-color: colors(jf-light);
    border: 1px solid colors(jf-light);
    input {
      font-family: sans-serif;
      background-color: colors(jf-white) !important;
      min-height: 33px;
    }
  }
  .jf-btn {
    font: 16px/22px fonts(jf-fontRegular);
  }

  .jf-check-circle-btn {
    border: 1px solid colors(jf-primary);
    background: transparent;
    padding: 0;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .jf-timebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-title {
      color: colors(jf-black);
      margin-top: 10px;
      width: 100%;
      font: 14px/16px fonts(jf-fontRegular);
    }
    .jf-max-range {
      color: colors(jf-black2);
      font: 14px/16px fonts(jf-fontRegular);
      margin-top: 4px;
    }
    .jf-select-range {
      margin-top: 10px;
      border-radius: 3px;
      color: colors(jf-white);
      background-color: colors(jf-primary);
      padding: 5px 10px;
      width: fit-content;
      font: 14px/16px fonts(jf-fontRegular);
    }

    input[type="range"]{
      margin-top: 10px;
      -webkit-appearance: none;
      width: 100%;
      height: 5px;
      outline: none !important;
      appearance:none;
      border:none;
      border-radius:30px;
    }
    input[type="range"]::-moz-focus-outer {
      border: 0;
    }
    input[type="range"]:hover {
      outline:none;
    }

    /* Chrome */

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: colors(jf-primary);
      cursor: pointer;
      border-radius:30px;
      outline:none;
    }

    /* Moz */

    input[type="range"]::-moz-range-thumb {
      width: 15px;
      height: 15px;
      background: colors(jf-primary);
      cursor: pointer;
      border-radius:50%;
      border:none;
      outline:none;
    }
    input[type="range"]::-moz-range-progress {
      background-color: colors(jf-primary);
      height: 100%;
      border-radius:30px;
      border:none;
    }
    input[type="range"]::-moz-range-track {
      background-color: rgba(124, 123, 253, 0.1);
      border-radius:30px;
      border:none;
      height: 100%;
    }
  }
}
