.react-tags {
  position: relative;

  font-size: 1em;
  line-height: 1.2;

  cursor: text;
  min-height: 46px;
  background-color: #f5f5f7 !important;
  color: colors(jf-black2);
  font: 18px/26px " JuneFox Regular";
  border: 2px solid #f5f5f7;
  border-radius: 5px;
}

.react-tags.is-focused {
  background-color: #f5f5f7 !important;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  border: none;

  font: 18px/26px " JuneFox Regular";
  align-items: center;
  background: colors(jf-primary);
  border-radius: 5px;
  color: colors(jf-white);
  font-weight: 300;
  list-style: none;
  margin: 5px;
  padding: 5px 10px;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: colors(jf-white);
  margin-left: 8px;
}

.react-tags__search {
  display: inline-block;
  max-width: calc(100% - 40px);

  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;

    background-color: colors(jf-light2) !important;
    color: colors(jf-black2);
    font: 18px/26px fonts(jf-fontRegular);
    min-width: 50px;
  }
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  max-height: 295px;
  overflow: auto;
  position: absolute;
  z-index: 7;
  background: colors(jf-white);
  width: 100%;
  border: 1px solid colors(jf-light2);
  border-radius: 5px;
  font: 18px/20px fonts(jf-fontRegular);
}

.react-tags__suggestions li {
  padding: 15px 20px 15px 20px;
  border: 1px solid white;
  &:hover {
    background-color: colors(jf-light2);
  }
  &.active {
    background-color: colors(jf-light2);
  }
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background-color: colors(jf-light2);
}

.react-tags__suggestions li.is-active {
  background-color: colors(jf-light2);
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
