.jf-bottom-menu {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  min-height: 60px;
  background-color: colors(jf-primary);

  &.type {
    opacity: 0.75;
  }

  &.hide {
    background-color: transparent !important;
  }

  &.type2 {
    background-color: colors(jf-white);

    svg:nth-child(1) {
      path:nth-child(2), rect {
        fill: colors(jf-primary);
      }
    }
  }

  position: sticky;
  bottom: 0;
  z-index: 5;

  &-ul {
    margin: 0;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;

    &-li {
      list-style: none;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-items: center;

      &:last-child {
        margin-right: 0;
      }

      &-a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-items: center;
        text-decoration: none;
        flex-flow: column;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }

        &.jf-active {
          opacity: 1;
        }
      }
    }
  }
}
