.jf-basic-page {
  padding: 20px;
  flex-grow: 1;
  overflow: auto;
  &.no-padding {
    padding: 0;
  }
}

.jf-flex-basic-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.jf-hide {
  display: none !important;
}
.jf-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.jf-error {
  color: colors(jf-error) !important;
}

.jf-flex {
  &-row{
    display: flex;
    flex-direction: row;
  }
  &-col {
    display: flex;
    flex-direction: column;
  }
}

.jf-pointer {
  cursor: pointer;
}

.jf-m-auto {
  margin: auto;
}

.jf-ml {
  &-20px {
    margin-left: 20px;
  }
  &-15px {
    margin-left: 15px;
  }
  &-10px {
    margin-left: 10px;
  }
  &-5px {
    margin-left: 5px;
  }
}
.jf-mr {
  &-20px {
    margin-right: 20px;
  }
}
.jf-pl {
  &-20px {
    padding-left: 20px;
  }
  &-15px {
    padding-left: 15px;
  }
}
.jf-pr {
  &-20px {
    padding-right: 20px;
  }
}
.jf-mt {
  &-auto {
    margin-top: auto;
  }
  &-3px {
    margin-top: 3px;
  }
  &-15px {
    margin-top: 15px;
  }
  &-20px {
    margin-top: 20px;
  }
  &-10px {
    margin-top: 10px;
  }
}
.jf-mb {
  &-auto {
    margin-bottom: auto;
  }
  &-15px {
    margin-bottom: 15px;
  }
  &-20px {
    margin-bottom: 20px;
  }
  &-12px {
    margin-bottom: 12px;
  }
  &-50px {
    margin-bottom: 50px;
  }
}
.jf-font {
  &-20-22 {
    font-size: 20px;
    line-height: 22px;
  }
  &-20-27 {
    font-size: 20px;
    line-height: 27px;
  }
  &-16-22 {
    font-size: 16px;
    line-height: 22px;
  }
  &-b {
    font-family: fonts(jf-fontBold);
  }
  &-r {
    font-family: fonts(jf-fontRegular) !important;
  }
  &-m {
    font-family: fonts(jf-fontMedium);
  }
}
.jf-color {
  &-black {
    color: colors(jf-black)
  }
  &-gray {
    color: rgba(colors(jf-black), 0.5)
  }
}
.jf-pt {
  &-10px {
    padding-top: 10px !important;
  }
}
.jf-pb {
  &-10px {
    padding-bottom: 10px !important;
  }
  &-20px {
    padding-bottom: 20px !important;
  }
}

.jf-gap {
  &-10px {
    gap: 10px;
  }
}

.jf-basic-list-item {
  overflow: hidden;
  margin-bottom: 20px;
  &>div {
    float: left;
  }
  &-detail {
    margin-left: 15px;
    &-header {
      font: 18px/24px fonts(jf-fontBold);
      color: colors(jf-black2);
    }
    &-body {
      font: 16px/21px fonts(jf-fontRegular);
      color: colors(jf-black3);
    }
  }
  &-left {
    float: right !important;
    margin-top: 8px;
  }
}

.jf-spin-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jf-account-save-btn {
  width: 134px;
  margin: 30px auto;
}


@media only screen and (min-width: $width-to-desktop) {
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: colors(jf-light) transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: colors(jf-light);
    border-radius: 20px;
    border: 3px solid transparent;
  }
}