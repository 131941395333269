.jf-studio-upload-page {
  display: flex;
  flex-direction: column;
  section.container {
    flex-grow: 1;
  }
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23707070FF' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 20px;
    background-color: colors(jf-light2);
    color: colors(jf-gray);
    outline: none;
    transition: border .24s ease-in-out;
    height: 160px;
    p {
      margin: auto;
      font: 22px/26px fonts(jf-fontRegular);
    }
  }
  aside>div {
    font: 22px/26px fonts(jf-fontRegular);
    color: colors(jf-black2);
  }
  ul {
    padding: 0;
    list-style: none;
    overflow: hidden;
    background: colors(jf-white);
    width: 100%;
    border: none;
    font: 14px/24px fonts(jf-fontMedium);
    color: colors(jf-gray);
    margin-top: 30px;
    border-bottom: 1px solid colors(jf-light);
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    li {
      padding: 0 0 15px 0;
      border: 1px solid white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      button {
        width: 30px;
        svg {
          margin-bottom: -3px;
        }
      }
      .jf-file-size {
        font-family: fonts(jf-fontBold);
        float: right;
      }
    }
  }
  .jf-warning {
    margin-top: 20px;
    font: 20px/24px fonts(jf-fontRegular);
    color: colors(jf-gray);
    svg {
      margin-bottom: -5px;
      margin-right: 10px;
    }
  }
  .jf-info {
    margin-top: -25px;
  }
  .jf-btn-group {
    margin-top: 20px;
    text-align: center;
    gap: 15px;
    div {
      margin-top: 20px;
      margin-bottom: 10px;
      font: 20px/24px fonts(jf-fontMedium);
      color: colors(jf-gray);
    }
  }
  .jf-basic-btn {
    font: 16px/19px fonts(jf-fontMedium);
    &.outline-btn:hover {
      color: colors(jf-primary);
    }
  }
}
.jf-text-field.error {
  .react-tags {
    border: 1px solid colors(jf-error);
  }
}
.jf-addcontent-btns {
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
  .jf-addbtn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 100%;
    background-color: colors(jf-light2);
    height: 180px;
    &.jf-livestreaming {
      margin: 20px 0;
    }
    .jf-btntitle {
      margin-top: 20px;
      color: colors(jf-black);
      font: 22px/26px fonts(jf-fontMedium);
    }
  }
  .jf-thumbnail-up {
    .jf-btntitle {
      margin-bottom: 0;
    }
  }
}
.jf-input-content {
  .jf-addcontentbox {
    display: flex;
    background-color: colors(jf-light);
    width: 230px;
    margin: 30px auto;
    justify-content: center;
    padding: 20px;
    flex-flow: row wrap;
    .jf-hint {
      display: flex;
      flex-flow: row wrap;
      margin: 20px 0 0;
      text-align: center;
      font: 22px/26px fonts(jf-fontMedium);
      color: colors(jf-primary);
    }
  }
  .jf-subtitle {
    display: flex;
    flex-flow: row wrap;
    text-align: center;
    color: colors(jf-black);
    font: 20px/24px fonts(jf-fontRegular);
    padding: 0 20px;
  }
}
.jf-studio-upload {
  display: flex;
  flex-direction: column;
  .jf-text-field-input input, .react-tags input{
    height: 60px;
  }
  .jf-text-field {
    width: 100%;
  }
}
.jf-content-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  flex-grow: 1;
  .jf-content-previewBox {
    display: flex;
    flex-flow: row wrap;
    margin: 0 20px;
    overflow: hidden;
    .video-js {
      width: 100%;
    }
    video, img {
      width: 100%
    }
  }
  .jf-content-title {
    margin: 20px;
    font: 20px/24px fonts(jf-fontRegular);
    color: colors(jf-black);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .jf-editicon {
    margin-left: 20px;
    margin-top: 10px;
  }
}
.jf-editform {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 40px);
  padding: 0 20px;
  gap: 20px;
  .jf-inputbox {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-flow: row wrap;
    &-title {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      color: colors(jf-black);
      font: 18px/21px fonts(jf-fontRegular);
      margin-bottom: 15px;
    }
    .jf-input {
      width: 100%;
      outline: none;
      border: none;
      padding: 0 15px;
      border-radius: 5px;
      background-color: colors(jf-light2);
      height: 60px;
      font: 18px/22px fonts(jf-fontRegular);
      color: colors(jf-black);
      align-items: center;
      display: flex;
      .jf-taguser {
        display: flex;
        border-radius: 20px;
        width: fit-content;
        height: 40px;
        background-color: colors(jf-primary);
        align-items: center;
        padding: 0 13px 0 5px;
        &-avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: colors(jf-secoundry);
          color: colors(jf-white);
          font: 16px/22px fonts(jf-fontBold);
        }
        .jf-username {
          color: colors(jf-white);
          margin-left: 10px;
          font: 16px/22px fonts(jf-fontRegular);
        }
      }
    }
    .jf-tags-hints {
      font: 16px/22px fonts(jf-fontRegular);
      margin-top: 15px;
      color: colors(jf-black2);
    }
    .jf-viewable-tabs {
      display: flex;
      flex-flow: row;
      width: 100%;
      border-radius: 5px;
      background-color: colors(jf-light2);
      height: 60px;
      &-li {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        color: colors(jf-black);
        width: 100%;
        border-radius: 5px;
        font: 16px/22px fonts(jf-fontRegular);
        cursor: pointer;
        &.jf-active {
          background-color: colors(jf-primary);
          color: colors(jf-white);
        }
      }
    }
  }
  .jf-editaction_btn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    margin: 15px 0;
    .jf-btn {
      border: none;
      width: 110px;
      margin: 0 auto;
      font: 20px/24px fonts(jf-fonrMedium);
    }
    .jf-btn2 {
      border: none;
      background: none;
      outline: none;
      font: 20px/24px fonts(jf-fonrMedium);
      color: colors(jf-black2);
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .jf-basic-btn.outline-btn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

// subsriber tabs content start
.jf-Subscribers {
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  .jf-inputbox-title {
    width: 100%;
    display: flex;
  }
  .jf-release-tabs {
    background-color: colors(jf-light2);
    height: 60px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    &-li {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      color: colors(jf-black);
      width: 100%;
      border-radius: 5px;
      font: 16px/22px fonts(jf-fontRegular);
      cursor: pointer;
      &.jf-active {
        background-color: colors(jf-primary);
        color: colors(jf-white);
      }
    }
  }
}
.jf-scheduled-content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 15px;
  .jf-inputbox {
    position: relative;
  }
  .jf-timer {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    &-tabs {
      display: flex;
      flex-flow: row;
      width: 100%;
      background-color: colors(jf-light2);
      align-items: center;
      border-radius: 5px;
      height: 60px;
      &-li {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        color: colors(jf-black);
        width: 50%;
        border-radius: 5px;
        font: 16px/22px fonts(jf-fontRegular);
        cursor: pointer;
        height: 100%;
        &.jf-active {
          background-color: colors(jf-primary);
          color: colors(jf-white);
        }
      }
    }
  }
}
.jf-everyone-content {
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  .jf-release-tabs {
    background-color: colors(jf-light2);
    height: 60px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    &-li {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      color: colors(jf-black);
      width: 100%;
      border-radius: 5px;
      font: 16px/22px fonts(jf-fontRegular);
      cursor: pointer;
      &.jf-active {
        background-color: colors(jf-primary);
        color: colors(jf-white);
      }
    }
  }
}