.jf-favorite-btn {
  text-align: center;
  width: fit-content;
  .jf-favorite-btn-icon {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  &-count {
    margin-top: 3px;
    font: 16px/21px fonts(jf-fontRegular);
    color: colors(jf-placeholder2);
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .jf-favorite-btn-icon:hover {
    svg g, svg path{
      fill: colors(jf-like);
    }
  }
}