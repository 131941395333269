.jf-back-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(colors(jf-primary), 0.05);
  transition: $transitions;
  cursor: pointer;
  &:hover {
    background-color: rgba(colors(jf-primary), 1);
    svg path {
      fill: colors(jf-white);
    }
  }
}
