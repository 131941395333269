.jf-popup-subscription {
  max-width: 450px;
  .jf-avatar {
    margin: auto;
    margin-top: -60px;
    border: 5px solid colors(jf-white);
    border-radius: 50%;
  }
  &-title {
    font: 32px/43px fonts(jf-fontBold);
    text-align: center;
    margin-top: 15px;
  }
  &-content {
    font: 20px/27px fonts(jf-fontRegular);
    margin: 10px 30px 20px;
  }
  .jf-row {
    align-items: baseline;
    &>div {
      margin-left: 5px;
    }
    &>div:nth-child(2) {
      width: calc(100% - 25px);
    }
  }
  .jf-basic-btn {
    width: 134px;
  }
  .jf-popup-close-btn {
    margin-top: 75px;
    position: absolute;
    right: 0;
  }
}


@media only screen and (max-width: $width-to-desktop) {
  .jf-popup-subscription {
    .jf-basic-btn {
      width: calc(100% - 40px);
    }
  }
}