.jf-videorecord {
  display: flex;
  width: 100%;
  flex-direction: column;
  &-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    position: relative;
    background-color: colors(jf-black2);
    .jf-sound {
      display: flex;
      align-items: center;
      position: absolute;
      top: 30px;
      z-index: 2;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      button {
        color: colors(jf-white);
        font: 16px/22px fonts(jf-fontRegular);
        display: flex;
        flex-direction: row;
        max-width: 150px;
        text-align: center;
        .jf-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .jf-icons {
        margin-right: 5px;
        width: 20px;
        height: 20px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
      }
    }
    .jf-shoottimers {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 90px;
      &-li {
        list-style: none;
        margin: 0 15px;
        &-a {
          color: colors(jf-white);
          font: 20px/22px fonts(jf-fontMedium);
          opacity: 0.5;
          &.jf-active {
            opacity: 1;
          }
        }
      }
    }
    .jf-videofooter {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: absolute;
      bottom: 20px;
      .jf-flex {
        flex: 0 0 33.33%;
        align-items: center;
        .jf-videoicon {
          width: 50px;
          height: 50px;
          border-radius: 30px;
          background-color: colors(jf-primary);
          margin: 0 auto;
          position: relative;
          cursor: pointer;
          display: flex;
          &::before {
            content: "";
            left: -5px;
            top: -5px;
            border: solid 2px rgba(colors(jf-primary), 1);
            width: 56px;
            height: 56px;
            border-radius: 30px;
            position: absolute;
          }
        }
        .jf-videobtn {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 20px;
          width: fit-content;
        }
      }
      &.jf-videofooter-recording {
        .jf-flex {
          .jf-videoicon {
            width: 56px;
            height: 56px;
            border-radius: 30px;
            border: solid 2px rgba(colors(jf-primary), 1);
            margin: 0 auto;
            position: relative;
            cursor: pointer;
            display: flex;
            background-color: transparent;
            &::before {
              content: "";
              left: 14px;
              top: 14px;
              width: 20px;
              height: 20px;
              background-color: #7c7bfd;
              border-radius: 5px;
              position: absolute;
            }
          }
        }
      }
    }
    .jf-icons {
      display: flex;
      align-items: center;
      height: 50px;
      .jf-backicon {
        cursor: pointer;
        padding-left: 10px;
      }
      .jf-checkmarkround {
        cursor: pointer;
        padding-left: 25px;
      }
    }
    .jf-recording-timeline {
      position: absolute;
      width: 100%;
      top: 10px;
      display: flex;
      flex-direction: column;
      &>div {
        margin: 0 20px;
      }
    }
  }
}
