.jf-support-page {
  padding: 0;

  .jf-avatar {
    width: 96px;
    height: 96px;
    background-color: colors(jf-light2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: fonts(jf-fontBold);
    font-size: 25px;
    position: relative;

    .jf-avatar-june {
      color: colors(jf-primary);
    }

    .jf-avatar-fox {
      color: colors(jf-logo);
    }

    .jf-avatar-verify {
      position: absolute;
      bottom: 3px;
      right: 0px;
    }
  }

  .jf-support-content {
    padding: 0 20px;
    margin-top: 10px;
  }

  .jf-collapse {
    .jf-collapse-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 17px;
      color: colors(jf-black2);
      padding: 0 20px;

      p {
        flex: 1;
      }
    }
  
    .jf-collapse-content {
      display: none;
  
      .jf-collapse-description {
        font-size: 14px;
        color: colors(jf-gray);
        padding: 0 20px;
      }
    }
  
    &.open {
      > .jf-collapse-content {
        display: block;
        padding: 5px 0;
      }
    }
  
    border-bottom: 1px solid rgba(colors(jf-gray), 0.1);
  
    &.jf-collapse-sub {
      border-bottom: 0px;
  
      &.open {
        background-color: #F2F2F2;
      }
  
      .jf-collapse-header {
        font-size: 16px;
        color: colors(jf-primary);
        padding-right: 25px;
  
        p {
          margin: 10px 0;
        }
      }
    }
  }
}
