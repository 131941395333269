.jf-media-tabs {
  position: relative;
  margin-top: 10px;
  width: 100%;
  .jf-media-list-note {
    width: 100%;
    position: absolute;
    text-align: center;
    margin-top: 17px;
    color : colors(jf-gray);
    font: 22px/26px fonts(jf-fontRegular);
  }
  &-header {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    li {
      box-sizing: border-box;
      flex: 1;
      width: 33.33%;
      padding: 5px 0;
      text-align: center;
      border: colors(jf-white) 1px solid;
      &.active {
        border-bottom: colors(jf-secondary) 2px solid;
        svg path, svg g {
          stroke: colors(jf-secondary);
        }
        #image-icon {
          stroke: none;
          fill: colors(jf-secondary);
        }
      }
    }
  }

  &-list {
    width: 100%;
    height: 100%;

    .jf-media-tab-row {
      gap: 10px;
      margin-bottom: 5px;
      min-height: 150px;
      .jf-seek-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(colors(jf-primary), 0.1);
      }
    }
  }

  .jf-media-list-item {
    width: 100%;
    position: relative;
    video, img {
      width: 100%;
    }

    &-cover {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
      height: calc(100% - 5px);
      cursor: pointer;
      &.subscription {
        background-color: rgba(colors(jf-black), 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .note {
          color: colors(jf-white);
          font: 14px/20px fonts(jf-fontMedium);
          text-align: center;
        }
      }
    }
    .type-2 {
      .jf-media-list-item-cover {
        min-height: 340px;
      }
    }

    &-label {
      position: absolute;
      text-align: center;
      width: calc(100% - 20px);
      bottom: 15px;
      margin: 0 10px;
      background-color: colors(jf-warning);
      font: 13px/24px fonts(jf-fontMedium);
    }

    &-detail {
      display: flex;
      position: absolute;
      top: calc(100% - 37px);
      left: 10px;
      font: 13px/24px fonts(jf-fontMedium);
      color: colors(jf-white);
      height: 19px;
      background-color: colors(jf-black2);
      opacity: 0.5;
      border-radius: 2px;
      padding: 1px 5px 2px 5px;
    }
  }
}