$fonts: (
  jf-fontUltralight: " JuneFox Ultra Light",
  jf-fontThin: " JuneFox Thin",
  jf-fontLight: " JuneFox Light",
  jf-fontRegular: " JuneFox Regular",
  jf-fontMedium: " JuneFox Medium",
  jf-fontBold: " JuneFox Bold",
  jf-fontHeavy: " JuneFox Heavy",
  jf-fontBlack: " JuneFox Black",
  jf-vijaya-regular: "JuneFox Vijaya Regular",
  jf-vijaya-bold: "JuneFox Vijaya Bold",
);

$colors: (
  jf-primary: #7c79fa,
  jf-secondary: #403bfa,
  jf-white: #ffffff,
  jf-black: #000000,
  jf-black2: #130c18,
  jf-black3: #51525b,
  jf-black4: #141822,
  jf-black5: #161823,
  jf-black6: #0a0c17,
  jf-black7: #3e3f5e,
  jf-inputborder: #eaeaf5,
  jf-placeholder: #667685,
  jf-forgotpass: #afafb3,
  jf-inputbtn: #cccccc,
  jf-secondary2: #03b4c4,
  jf-placeholder2: #8b8c90,
  jf-border: #dcdcdd,
  jf-border2: #e3e3e4,
  jf-border3: #8c8d91,
  jf-border4: #e3e4e5,
  jf-border5: #f1f1f1,
  jf-border6: #6C757D,
  jf-social: #4e4f59,
  jf-tag: #facd33,
  jf-online: #4ff461,
  jf-chatsbg: #f5f5fa,
  jf-timeline: #adafca,
  jf-twitter: #03a9f4,
  jf-btn: #7c79fa,
  jf-like: #fb3761,
  jf-error: #ff7a7a,
  jf-gray: #595D73,
  jf-light: #e6e6e6,
  jf-light2: #f5f5f7,
  jf-warning: #EED600,
  jf-live: #D856C8,
  jf-logo: #23D2E2,
  jf-frame: #F7F7FF,
);

$width-to-desktop: 900px;
$thumbnail-height-portrait: 300px;
$thumbnail-height-landscape: 150px;

$transitions: all 0.3s ease;
$image-storage: (
  modelImage: (
    "model-1": url("../../assets/media/model/model-1.jpg"),
  ),
);

:export {
  colors: {
    @each $key, $value in $colors {
      #{unquote($key)}: $value;
    }
  }
}

:export {
  fonts: {
    @each $k, $v in $fonts {
      #{unquote($k)}: $v;
    }
  }
}