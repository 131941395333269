.jf-otp {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
  height: 100vh;
  .jf-header {
    width: 100%;
  }
  &-body {
    // display: flex;
    flex-flow: row wrap;
    width: calc(100% - 80px);
    padding: 0 40px;
    height: calc(100% - 70px);
    .jf-body-title {
      margin: 60px 0 15px;
      font: 32px/36px fonts(jf-fontBold);
      color: colors(jf-black2);
      display: flex;
      flex-flow: row wrap;
      width: 100%;
    }
    .jf-subtitle {
      margin: 0;
      font: 18px/26px fonts(jf-fontRegular);
      color: colors(jf-black3);
      width: 100%;
      display: flex;
    }
    .jf-form-box {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      width: 100%;
      margin: 30px 0;
      .jf-input-box {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        border: solid 2px rgba(colors(jf-inputborder), 1);
        padding: 2px;
        display: flex;
        margin-right: 18px;
        &:nth-last-child(1) {
          margin: 0;
        }
        .jf-input-otp {
          width: 100%;
          height: 100%;

          outline: none;
          border: none;
          font-size: 42px;
          text-align: center;
        }
      }
    }
    .jf-resend-code {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      font: 18px/21px fonts(jf-fontRegular);
      color: colors(jf-black3);
      .jf-timer {
        color: colors(jf-secondary);
        margin-left: 5px;
      }
    }
  }
  .jf-next {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(colors(jf-primary), 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    transition: $transitions;
    &:hover {
      background-color: colors(jf-primary);
    }
  }
}
