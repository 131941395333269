.jf-select-tab {
  display: flex;
  flex-direction: row;
  background-color: colors(jf-light2);
  border-radius: 5px;
  justify-content: center;
  min-height: 45px;
  &-li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: colors(jf-black);
    font: 16px/22px fonts(jf-fontMedium);
    cursor: pointer;
    transition: $transitions;
    flex-grow: 1;
    &.jf-active {
      background-color: colors(jf-primary);
      color: colors(jf-white);
    }
  }
}