.jf-header {
  display: flex;
  flex-flow: row wrap;
  height: 70px;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(94, 92, 154, 0.1);
  padding: 0 20px;
}

.jf-logo {
  display: flex;
  font: 62px/42px fonts(jf-vijaya-bold);
  div:first-child {
    color: colors(jf-primary);
    margin-left: 40px;
  }
  div:last-child {
    color: colors(jf-logo);
  }
  &.small {
    font: 32px/32px fonts(jf-vijaya-bold);
    div:first-child {
      margin-left: 15px;
    }
  }
  svg{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    margin: 10px 5px;
  }
}

@media only screen and (max-width: $width-to-desktop) {
  .jf-logo {
    font: 32px/12px fonts(jf-vijaya-bold);
    div:first-child {
      margin-left: 10px;
    }
  }
}