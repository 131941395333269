.jf-item-menu {
  display: flex;
  min-height: 70px;
  border: solid 1px colors(jf-border5);
  align-items: center;
  cursor: pointer;
  width: calc(100% - 43px);
  padding: 0 20px;
  &.none-border {
    border: none;
    padding: 0;
    width: 100%;
  }
  &-icon {
    height: 24px;
    width: 24px;
    svg {
      height: 100% !important;
      width: 100% !important;
    }
    &.greater-icon {
      width: 11px;
      height: 20px;
    }
  }
  &-text {
    color: colors(jf-black2);
    font: 18px/26px fonts(jf-fontRegular);
    flex-grow: 1;
  }
}

.jf-item-menu-multi {
  border: solid 1px colors(jf-border5);
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  &-icon {
    height: 22px;
    width: 22px;
    svg {
      height: 100% !important;
      width: 100% !important;
    }
  }
  &-text {
    color: colors(jf-black2);
    font: 20px/27px fonts(jf-fontRegular);
    flex-grow: 1;
  }
}