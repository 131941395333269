.jf-forgot-pass-content {
  width: 100%;
  min-height: 100vh;

  .jf-input-boxs {
    display: flex;
    flex-direction: column;
    .jf-text-field {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .jf-btn-ul {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    &-li {
      width: 100%;
      justify-content: center;
      display: flex;
      color: colors(jf-black3);
      text-transform: uppercase;
    }
  }
  

  .jf-forgot-pass-body {
    display: flex;
    flex-flow: row wrap;
    padding: 0 40px;

    .jf-title {
      margin: 35px 0 13px;
      font: 32px/42px fonts(jf-fontBold);
      color: colors(jf-black2);
      display: flex;
      flex-flow: row wrap;
      width: 100%;
    }

    .jf-forgot-pass-loading {
      margin-top: 5px;
      margin-right: 10px;
    }
  }  
}
