.jf-popup-tips {
  max-width: 450px;
  .jf-avatar {
    margin: auto;
    margin-top: -60px;
    border: 5px solid colors(jf-white);
    border-radius: 50%;
  }
  &-title {
    font: 32px/43px fonts(jf-fontBold);
    text-align: center;
    margin-top: 10px;
  }
  &-note {
    color: colors(jf-gray);
    margin: 13px 0;
  }
  &-content {
    font: 18px/24px fonts(jf-fontRegular);
    margin: 20px;
    .jf-text-field {
      &-input {
        display: flex;
        background-color: colors(jf-light2);
        border-radius: 5px;
        input {
          height: 66px;
          font: 20px/27px fonts(jf-fontRegular);

          &:nth-child(2) {
            padding-left: 15px;
          }
        }

        svg {
          margin-left: 20px;
        }
      }
    }
  }
  .jf-basic-btn {
    width: 134px;
    padding: unset;
  }
  .jf-popup-close-btn {
    margin-top: 75px;
    position: absolute;
    right: 0;
  }
}


@media only screen and (max-width: $width-to-desktop) {
  .jf-popup-tips {
    .jf-basic-btn {
      width: calc(100% - 40px);
    }
  }
}