.jf-basic-btn {
  background-color: colors(jf-btn);
  cursor: pointer;
  border-radius: 30px;
  font: 20px/27px fonts(jf-fontMedium);
  height: 45px;
  &.save-btn {
    width: auto;
    min-width: 110px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
  }
  &.outline-btn {
    width: auto;
    min-width: 110px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    background: none;
    outline: none;
    font: 20px/24px fonts(jf-fontMedium);
    color: colors(jf-gray);
    cursor: pointer;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: none;
      color: colors(jf-gray);
    }
  }
  &.white-btn {
    background: none;
    font: 20px/24px fonts(jf-fontMedium);
    color: colors(jf-btn);
    border: 1px solid colors(jf-btn);
    margin: auto;
    &:hover {
      background: none;
      color: colors(jf-secondary);
      border-color: colors(jf-secondary);
    }
  }
  &.small {
    font: 16px/21px fonts(jf-fontBold);
    height: 34px;
    width: fit-content;
    padding: 0 10px;
  }
  &.medium {
       font: 18px/24px fonts(jf-fontMedium);
       height: 44px;
       width: fit-content;
       padding: 0 54px;
     }
  &.secondary {
    background-color: colors(jf-secondary);
  }
  &.bl-radius {
    border-radius: 0 0 0 5px;
  }
  &.br-radius {
    border-radius: 0 0 5px 0;
  }
}

.jf-basic-btn:hover {
  background-color: colors(jf-secondary);
  color: white;
  &.secondary {
    background-color: colors(jf-btn);
  }
}