.jf-comment-item {
  .jf-bottom-popup {
    &-header {
      text-align: center;
      margin-bottom: 26px;
      &-close-btn {
        float: right;
        padding: 0;
        border: none;
        background: none;
        svg {
          width: 10px;
          height: 10px;
          path {
            fill: colors(jf-black2);
          }
        }
      }
    }
    &-body {
      height: 466px;
      overflow: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-item {
      align-items: unset !important;
      height: auto !important;
      display: flow-root !important;
      border: none !important;
      &-left {
        float: left;
        margin-top: 5px;
        margin-right: 15px;
      }
      &-center {
        float: left;
        font: 18px/24px fonts(jf-fontBold);
        width: calc(100% - 125px);
        &-body {
          margin-top: 1px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font: 18px/24px fonts(jf-fontRegular);
        }
        &-footer {
          margin-top: 3px;
          font: 16px/21px fonts(jf-fontRegular);
          color: colors(jf-placeholder2);
        }
      }
      &-right {
        float: right;
        margin-top: 8px;
      }
    }
  }
}