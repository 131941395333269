.jf-header2 {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 2;
  &>a{
    flex-basis: 25%;
  }
  .jf-tabsmenu {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    flex-basis: 50%;
    justify-content: center;
    &-li {
      color: rgba(colors(jf-white), 0.5);
      font: 20px/26px fonts(jf-fontBold);
      margin: 0 0.5rem;
      cursor: pointer;
      &.jf-active {
        color: rgba(colors(jf-white), 1);
      }
    }
  }
}
@media only screen and (max-width: $width-to-desktop) {
  .jf-header2 {
    margin-top: -5px;

    .jf-tabsmenu {
      margin-top: 10px;
    }
  }
}
@media only screen and (min-width: $width-to-desktop) {
  .jf-swiper-arrow {
    width: 100%;
  }
}