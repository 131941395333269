.jf-purchases-page {
  padding: 0;
  display: flex;
  flex-direction: column;
  button, .jf-select-tab-li {
    font-size: 18px;
  }

  button.jf-cancel-btn {
    font-size: 12px;
    color: colors(jf-btn);
    background-color: rgba(colors(jf-btn), 0.1);
  }
  .jf-funds-note {
    padding: 20px 20px 15px 20px;
    color: colors(jf-gray);
    font: 20px/32px fonts(jf-fontMedium);
  }
  .jf-funds {
    .white-btn{
      width: 20%;
      margin: 0;
    }
    padding: 0 20px 15px 20px;
    justify-content: space-between;
  }
  .jf-purchases {
    &-wallet {
      display: flex;
      flex-direction: column;
      border: 1px solid colors(jf-light2);
      padding: 15px 20px;
      font: 20px/30px fonts(jf-fontRegular);
      div:nth-child(1) {
        height: 54px;
        font: 54px/54px fonts(jf-fontBold);
      }
      div:nth-child(2) {
        color: colors(jf-gray);
      }
    }
    &-period {
      display: flex;
      flex-direction: row;
      padding: 23px 20px;
      div:nth-child(1) {
        font: 20px/22px fonts(jf-fontRegular);
        flex-grow: 1;
        color: colors(jf-gray);
      }
      div:nth-child(2) {
        font: 20px/22px fonts(jf-fontMedium);
      }
    }
    &-transaction, &-subscriptions {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(colors(jf-gray), 0.1);
      justify-content: center;
      font: 16px/20px fonts(jf-fontRegular);
      align-items: center;
      div:nth-child(2) {
        font: 16px/20px fonts(jf-fontMedium);
        margin-left: 10px;
        flex-grow: 1;
      }
      div:nth-child(3) {
        width: 60px;
        color: colors(jf-primary);
        font: 16px/20px fonts(jf-fontBold);
      }
      div:nth-child(4) {
        width: 100px;
        color: colors(jf-gray);
      }
      div:nth-child(5) {
        width: 60px;
        color: colors(jf-gray);
      }
      .jf-active {
        color: colors(jf-primary);
      }
    }
  }
}