.jf-chat-area-contain {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .jf-chat-area {
    width: 100%;
    font: 18px/20px fonts(jf-fontRegular);
    &-msg {
      width: 100%;
      display: inline-block;
      word-break: break-word;
      &-left {
        max-width: calc(min(80%, 500px));
        background-color: colors(jf-chatsbg);
        border-radius: 0 10px 10px 10px;
        color: colors(jf-black2);
        padding: 12px 12px 7px 12px;
        margin-bottom: 5px;
      }

      &-right {
        float: right;
        max-width: calc(min(80%, 500px));
        background-color: colors(jf-secondary);
        border-radius: 10px 0 10px 10px;
        color: colors(jf-white);
        padding: 12px 12px 7px 12px;
        margin-bottom: 5px;
      }
    }
    &-time {
      width: 100%;
      font: 12px/18px fonts(jf-fontRegular);
      color: colors(jf-timeline);
      margin-bottom: 15px;
      margin-top: -5px;

      &.left {
        float: left;
      }

      &.right {
        float: right;
        text-align: right;
      }
    }
  }
}