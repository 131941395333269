.jf-users-detail {
  position: absolute;
  bottom: 90px;
  left: 20px;
  z-index: 3;
  pointer-events: none;
  max-width: 70%;
  overflow: hidden;

  &-name {
    margin: 0 0 10px 0;
    color: colors(jf-white);
    font: 18px/22px fonts(jf-fontRegular);

    .jf-span-username {
      color: colors(jf-white);
    }
  }

  &-title {
    margin: 0 0 5px 0;
    color: colors(jf-white);
    font: 16px/22px fonts(jf-fontRegular);
    max-height: 200px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-tag {
    margin: 0 0 5px 0;
    color: colors(jf-white);
    font: 14px/20px fonts(jf-fontRegular);

    .jf-span-tag {
      color: colors(jf-white);
      font: 14px/20px fonts(jf-fontRegular);
      margin-right: 8px;
    }
  }

  &-soundtrack {
    color: colors(jf-white);
    font: 14px/20px fonts(jf-fontRegular);
    display: flex;
    margin: 0;
    align-items: center;

    .jf-users-detail-soundtrack-icon {
      margin-right: 10px;
    }
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .jf-users-detail {
    position: absolute;
    bottom: 30px;
    margin: 0 auto;
    width: 80%;
    left: 10%;
    right: 10%;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;

    &-tag {
      margin: 0;
      width: 100%;
      text-align: center;
      color: colors(jf-white);
      font: 18px/22px fonts(jf-fontRegular);
    }

    &-soundtrack {
      display: flex;
      flex-flow: row wrap;
      color: rgba(colors(jf-white), 0.7);
      font: 18px/22px fonts(jf-fontMedium);
      align-items: center;
      margin-top: 15px;
    }
  }
}