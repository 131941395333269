.jf-messages-page {
  display: flex;
  flex-direction: column;
  padding: 0;
  .jf-select-tab {
    margin: 20px;
  }
  .jf-message-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .js-message-empty-text {
      text-align: center;
      color: red;
      width: 100%;
      position: relative;

      &::before {
        content: "";
        width: 30px;
        height: 4px;
        position: absolute;
        left: 20px;
        top: 8px;
        background: red;
        border-radius: 3px;
      }

      &::after {
        content: "";
        width: 30px;
        height: 4px;
        position: absolute;
        right: 20px;
        top: 8px;
        background: red;
        border-radius: 3px;
      }
    }
  }
  .jf-message-item {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-bottom: 1px solid rgba(colors(jf-black), 0.1);
    &-body {
      font: 18px fonts(jf-fontMedium);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 15px;
      justify-content: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & > div:nth-child(2) {
        margin-top: 5px;
        font: 16px fonts(jf-fontRegular);
      }
    }
    & > div:last-child {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      color: rgba(colors(jf-black2), 0.5);
      font: 14px fonts(jf-fontRegular);
      .jf-unread {
        font: 12px/24px fonts(jf-fontRegular);
        color: colors(jf-white);
        background-color: colors(jf-primary);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        text-align: center;
        margin-top: 15px;
        align-self: flex-end;
      }
    }
  }
}

.jf-automatedMessage {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  &-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px rgba(colors(jf-black2), 0.1);
    margin-bottom: 20px;
    &-text {
      display: flex;
      flex-direction: column;
      width: calc(100% - 70px);
      .jf-MessageTitle {
        font: 18px/21px fonts(jf-fontMedium);
        color: colors(jf-black);
      }
      .jf-MessageSubtitle {
        font: 16px/20px fonts(jf-fontRegular);
        color: rgba(colors(jf-black), 0.6);
        margin: 6px 0 10px;
      }
    }
  }
}
