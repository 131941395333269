.jf-footer {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  height: 70px;
  background-color: colors(jf-primary);
  position: fixed;
  bottom: 0;
  &-ul {
    margin: 0;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0;
    justify-content: center;
    &-li {
      list-style: none;
      display: flex;
      flex-flow: column;
      margin-right: 47px;
      align-items: center;
      justify-items: center;
      &:last-child {
        margin-right: 0;
      }
      &-a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-items: center;
        text-decoration: none;
        flex-flow: column;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
        &.jf-active {
          opacity: 1;
        }
      }
    }
  }
}
