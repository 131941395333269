.jf-signin-body {
  display: flex;
  flex-flow: row wrap;
  padding: 0 40px;
  .jf-title {
    margin: 35px 0 13px;
    font: 32px/42px fonts(jf-fontBold);
    color: colors(jf-black2);
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }
}
