.jf-dashbord {
  width: 100%;
  display: flex;
  //height: calc(100% - 60px);
  height: 100%;
  position: relative;
  justify-content: center;
  overflow: hidden;
  z-index: 0;
  flex-direction: column;
  background-color: colors(jf-black2);

  .jf-modal_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;

    video {
      object-fit: cover;
    }

    &-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .landscape {
    display: flex;

    .jf-modal_img {
      object-fit: contain;
    }

    video {
      object-fit: contain;
    }
  }

  .portrait {
    display: flex;
  }

  &.empty {
    opacity: 0.8;

    .jf-dashbord-message {
      font: 18px/22px fonts(jf-fontBold);
      color: colors(jf-white);
      margin: auto;
      background-color: colors(jf-black2);
    }
  }
}

.jf-user-pannel {
  position: absolute;
  right: 10px;
  bottom: 90px;
  display: flex;
  flex-direction: column;
  width: 60px;
  justify-items: center;
  z-index: 3;

  .jf-top-arrow {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
  }

  .jf-bottomarrow {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
  }

  .jf-icon-avatar {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .jf-icon-avatar-check-btn svg {
    width: 20px;
    height: 20px;
  }

  &-likes {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    color: colors(jf-white);
    margin-top: 15px;

    .jf-favorite-btn {
      text-align: center;
      width: 40px;

      &-icon {
        padding: 0;
        border: none;
        background: none;
      }

      &-count {
        margin-top: 0;
        font: 14px/20px fonts(jf-fontRegular);
        color: colors(jf-white);
      }
    }
  }

  &-comments {
    width: 100%;
    justify-content: center;
    display: flex;
    color: colors(jf-white);
    margin-top: 10px;
    flex-flow: row wrap;

    .jf-comment-icon {
      width: 40px;
      height: 40px;
      margin: 0 auto;
    }

    .jf-comment-span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font: 14px/20px fonts(jf-fontRegular);
    }
  }

  &-shares {
    width: 100%;
    justify-content: center;
    display: flex;
    color: colors(jf-white);
    flex-flow: row wrap;
    margin-top: 15px;

    .jf-shares-icon {
      width: 40px;
      height: 40px;
      margin: 0 auto;
    }

    .jf-shares-span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: -2px;
      font: 14px/20px fonts(jf-fontRegular);
    }
  }
}

.jf-dashboard-tag {
  width: 100%;
  flex-grow: 1;
  overflow: auto;

  .jf-media-tabs {
    height: calc(100% - 10px);
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .jf-dashbord {
    .jf-modal_img {
      object-fit: contain;

      video {
        object-fit: contain;
      }
    }
  }
  .jf-user-pannel {
    right: 1.25rem;
    bottom: 20px;

    font: 16px/24px fonts(jf-fontRegular) !important;

    .jf-fullscreen {
      margin-top: 5px;
    }
  }
  .jf-user-pannel .jf-icon-avatar {
    margin-bottom: 5px;
  }
}
