.jf-toggle-switch {
  text-align: center;
  margin-right: 8px;
  .toggle-switch {
    position: relative;
    width: 33px;
    height: 24px;
    display: inline-block;
    text-align: left;
    top: 3.5px;
  }

  .checkbox {
    display: none;
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid colors(jf-gray);
    border-radius: 20px;
  }

  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 16px;
    padding: 0;
    line-height: 16px;
    font-weight: bold;
    box-sizing: border-box;
  }

  .inner:before {
    content: "  ";
    padding-left: 10px;
    background-color: colors(jf-primary);
    opacity: 0.5;
  }

  .inner:after {
    content: "  ";
    padding-right: 10px;
    background-color: colors(jf-gray);
    text-align: right;
    opacity: 0.5;
  }

  .switch {
    display: block;
    width: 24px;
    position: absolute;
    top: -5px;
    bottom: 5px;
    right: 17.6px;
    border: 0 solid colors(jf-gray);
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    background: colors(jf-white);
    filter: drop-shadow(0 0 10px rgba(colors(jf-black), 0.16))
  }

  .checkbox:checked + .label .inner {
    margin-left: 0;
  }

  .checkbox:checked + .label .switch {
    right: -10px;
    background: colors(jf-primary);
  }
}
