.jf-write-area {
  display: flex;
  align-items: center;
  position: relative;
  background-color: colors(jf-primary);
  padding: 23px 20px;
  flex-direction: row;
  &-input {
    flex-grow: 1;
    input {
      width: 100%;
      display: flex;
      outline: none;
      border: none;
      height: 24px;
      background: transparent;
      font: 18px/24px fonts(jf-fontRegular);
    }
    [data-placeholder]:empty:before{
      font: 18px/24px fonts(jf-fontRegular);
      content: attr(data-placeholder);
      color: colors(jf-white);
      opacity: 50%;
    }
    .at-icon {
      color: colors(jf-white);
      font-size: 17px/24px;
      font-style: italic;
    }
  }
  &-tag-btn, &-emoji-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    margin-left: 20px;
  }
}