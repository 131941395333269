@import "variables.module";
@import "./mixins.scss";

@font-face {
  font-family: fonts(jf-fontUltralight);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Ultralight.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Ultralight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontThin);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Thin.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontLight);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Light.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontRegular);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Regular.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontMedium);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Medium.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontBold);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Bold.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontHeavy);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Heavy.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-fontBlack);
  src: url("../assets/fonts/sf-pro-display/SFProDisplay-Black.woff2") format("woff2"),
  url("../assets/fonts/sf-pro-display/SFProDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fonts(jf-vijaya-regular);
  src: url("../assets/fonts/vijaya/vijaya-webfont.woff2") format("woff2"),
  url("../assets/fonts/vijaya/vijaya-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: fonts(jf-vijaya-bold);
  src: url("../assets/fonts/vijaya/vijayab-webfont.woff2") format("woff2"),
  url("../assets/fonts/vijaya/vijayab-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  body {
    width: 100%;
    color: colors(jf-black2);

    a {
      text-decoration: none;
    }

    * {
      outline: none;

      &:focus {
        outline: none;
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
      }
    }
  }
}

.jf-main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.jf-btn {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 22px/40px fonts(jf-fontRegular);
  color: colors(jf-white);
  background-color: colors(jf-btn);
  text-transform: capitalize;
  border-radius: 5px;
  transition: $transitions;
  outline: none;
  border: none;

  &.jf-btn-primary {
    border-radius: 0 0 0 5px;
    background-color: colors(jf-primary);
  }

  &.jf-btn-secondary {
    border-radius: 0 0 5px 0;
  }

  &:active {
    background-color: rgba(colors(jf-primary), 0.05);
    color: colors(jf-primary);
  }

  &.jf-btn-secondary {
    background-color: colors(jf-secondary);
  }
}

.jf-btn2 {
  font: 22px/26px fonts(jf-fontRegular);
  color: colors(jf-black3);
  text-transform: capitalize;
  transition: $transitions;

  &:hover {
    color: colors(jf-primary);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 70px white inset !important;
  height: 100%;
}

.jf-header-two {
  height: 70px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 0px 20px rgba(94, 92, 154, 0.1);
  &-btn {
    display: flex;
    flex-flow: column;
  }

  &-title {
    font: 18px/22px fonts(jf-fontBold);
    color: colors(jf-black2);
  }

  &-icon {
    display: flex;
    flex-flow: column;
  }
}

.jf-container {
  width: 100%;
  position: fixed !important;
  height: 100%;
}

.jf-no-style-btn {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

#layers {
  z-index: 1000;

  .tooltip {
    background-color: colors(jf-black);
    color: colors(jf-white);
    text-align: center;
    border-radius: 6px;
    padding: 5px;

    svg path {
      fill: colors(jf-black);
    }
  }

  .popover-menu {
    background-color: colors(jf-white);
    text-align: center;
    border-radius: 6px;
    box-shadow: colors(jf-light) 0 1px 15px;
    border: 1px solid colors(jf-light);

    ul {
      padding: 0;
      list-style: none;
      border-radius: 6px;
      font: 18px/20px fonts(jf-fontRegular);

      li {
        padding: 15px 20px 15px 20px;
        border: 1px solid white;
        cursor: pointer;
        border-radius: 6px;

        &:hover {
          background-color: colors(jf-light2);
        }

        &.active {
          background-color: colors(jf-light2);
        }
      }
    }
  }
}

@media only screen and (max-width: $width-to-desktop) {
  .desktop-display {
    display: none !important;
  }
  .jf-main {
    position: fixed;
    height: 100%;
  }

  .video-container {
    video {
      height: inherit;
    }
  }
}

@media only screen and (min-width: $width-to-desktop) {
  .responsive-display {
    display: none !important;
  }
  #root > div:first-child {
    display: flex;

    .jf-media-desktop {
      background-color: colors(jf-black);
      flex-grow: 1;
      position: relative;
      z-index: 6;

      .jf-dashbord {
        height: 100vh;
      }
    }

    .jf-main {
      width: 414px;
      min-width: 414px;
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .jf-container {
      width: auto;
    }
  }
}

vds-media {
  display: inline-block;
  width: 100%;
}

vds-media:not(:defined) {
  visibility: hidden;
}

.video-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //height: 100%;
  //width: 100%;
  min-width: 0;
  min-height: 0;
  flex-basis: 100%;
  box-sizing: border-box;
  overflow: hidden;

  video {
    max-width: calc(100% - 1px);
    max-height: calc(100% - 1px);
    //max-width: 100%;
    //max-height: 100%;
    //width: 100%;
    //height: 100%;
    object-fit: cover;
    flex-grow: 1;
    display: block;
    //margin: auto;
    padding: 0;
    box-sizing: border-box;
  }
}