.jf-studio-live {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  .jf-avatar {
    margin: auto;
  }
  .jf-username {
    margin-top: 15px;
    text-align: center;
    color: colors(jf-gray);
    font: 18px/21px fonts(jf-fontRegular);
  }
  .jf-editform {
    margin-top: 20px;
    flex-grow: 1;
    flex-direction: column;
  }
  .jf-server-label {
    font: 18px/24px fonts(jf-fontRegular);
    span {
      color: colors(jf-primary)
    }
  }

  .jf-stream-key {
    height: 50px;
    background-color: colors(jf-light2);
    padding: 0 20px;
    color: colors(jf-black2);
    border: 2px solid colors(jf-light2);
    border-radius: 5px;
    font: 18px/50px fonts(jf-fontRegular);
    button {
      float: right;
      height: 50px;
      margin-left: 10px;
    }
  }
  .jf-basic-btn {
    text-transform: none;
  }
  .jf-dashbord {

  }
}

.jf-live-box {
  .jf-icon-avatar-check-btn {
    display: none;
  }
}

.jf-live-box-end {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: colors(jf-black);
  opacity: 0.5;

  &-content {
    position: absolute;
    margin: auto;
    z-index: 4;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    color: colors(jf-white);
    align-items: center;
    text-align: center;
    .title {
      font: 30px/36px fonts(jf-fontMedium);
      margin-bottom: 40px;
    }
    .jf-avatar {
      margin-bottom: 30px;
    }
    .username {
      font: 22px/26px fonts(jf-fontRegular);
      margin-bottom: 25px;
    }

    .jf-row {
      text-align: center;
      font: 22px/30px fonts(jf-fontMedium);
      div {
        margin: 0 15px;
        width: 120px;
      }
      label {
        font: 22px/26px fonts(jf-fontRegular);
        opacity: 0.65;
      }
    }
  }
}

.jf-live-chat {
  position: absolute;
  bottom: 0;
  width: 475px;
  left: calc(50% - 245px);
  height: 35%;
  display: flex;
  flex-direction: column;
  color: colors(jf-white);

  &-body {
    padding: 10px 15px;
    flex-grow: 1;
    &.chat {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      /* Works on Firefox */
      scrollbar-width: thin;
      scrollbar-color: rgba(200,200,200,0.3) transparent;

      /* Works on Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(200,200,200,0.3);
        border-radius: 20px;
        border: 3px solid transparent;
      }

      .chat__message {
        display: flex;
        flex-direction: row;
        font: 14px/19px fonts(jf-fontRegular);
        margin-top: 10px;
        .jf-avatar {
          margin-right: 10px;
          min-width: 40px;
        }
        &>div:nth-child(2) {
          flex-grow: 1;
          flex-direction: column;
          display: flex;
        }
      }
    }
  }

  .jf-live-chat-footer {
    padding: 10px 15px;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    .jf-live-chat-input {
      border-radius: 25px;
      background-color: rgba(#7a7a7a00, 0.2);
      flex-grow: 1;
      padding: 15px 20px;
      border: none;
      color: colors(jf-white);
      &-icon-btn {
        height: 50px;
        min-width: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: rgba(#7a7a7a00, 0.2);
        border: none;
        cursor: pointer;
        display: flex;
        margin-left:10px;

        &:hover {
          svg path {
            fill: colors(jf-secondary);
          }
        }
      }
    }
  }
}

.jf-user-live-info {
  position: absolute;
  z-index: 1;
  margin: 15px;
  color: colors(jf-white);
  width: calc(100% - 30px);
  .jf-follow-label {
    margin-left: 15px;
    font-family: fonts(jf-fontRegular);
  }
}

@media only screen and (max-width: $width-to-desktop) {
  .jf-live-user {
    .jf-dashbord {
      height: 100%;
    }
  }
  .jf-live-chat {
    width: 100%;
    left: 0;
    .jf-live-chat-input-icon-btn:hover {
      svg path {
        fill: colors(jf-white) !important;
      }
    }
  }
}