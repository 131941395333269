.jf-top-menu {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 70px;
  align-items: center;
  box-shadow: 0 0 20px rgba(94, 92, 154, 0.1);
  background-color: colors(jf-white);
  z-index: 2;
  .jf-left {
    margin-left: 10px;
    width: 80px;
    .jf-icon-back {
      display: flex;
      flex-flow: row wrap;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: rgba(colors(jf-primary), 0.05);
      transition: $transitions;
      cursor: pointer;

      svg {
        transform: rotate(180deg);
        height: 20px;
        width: 10.91px;
        path {
          fill: colors(jf-primary);
          stroke: colors(jf-primary);
        }
      }
      &:hover {
        background-color: rgba(colors(jf-primary), 1);
        svg path {
          fill: colors(jf-white);
        }
      }
    }
  }
  .jf-center {
    flex-grow: 1;
    text-align: center;
    span {
      font: 20px/22px fonts(jf-fontMedium);
    }
  }
  .jf-right {
    width: 80px;
    margin-right: 10px;
  }
}
