.jf-messages-inbox-page {
  padding: 0;
  .jf-messages-item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .jf-messages-left {
    justify-content: start;
    padding-left: 15px;
    border-bottom-left-radius: 0;
    .jf-messages-value {
      margin-left: 15px;
    }
  }
  .jf-messages-right {
    justify-content: flex-end;
    padding-right: 15px;
    border-bottom-right-radius: 0;
  }

  .jf-messages-value {
    background-color: colors(jf-frame);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    font: 17px/22px fonts(jf-fontRegular);
    &.text {
      max-width: 250px;
      word-break: break-word;
    }

    &.teaser, &.upload {
      img, .video-js {
        width : 284px;
        height: 213px;
        object-fit: contain;
        border-radius: 5px;
      }
      .jf-img-cover {
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        width : 284px;
        height: 213px;
        background-color: colors(jf-black2);
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .jf-messages-price {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(colors(jf-border6), 0.2);
        color: rgba(colors(jf-border6), 0.5);
        svg path {
          fill: rgba(colors(jf-border6), 0.5);
        }
        button {
          font: 14px/22px fonts(jf-fontMedium);
          height: 35px;
        }
      }
    }

    &.uploading {
      .load-container {
        width : 300px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(colors(jf-primary), 0.1);
      }
    }
  }
}
.jf-popup-comment-footer.jf-write-box {
  &.jf-messages-inbox-footer {
    flex-direction: row;
    background-color: white;
    .jf-write-box-input {
      font-family: sans-serif;
      color: colors(jf-primary);
      &::placeholder {
        color: colors(jf-primary);
      }
      &::-webkit-input-placeholder {
        color: colors(jf-primary);
      }
    }
  }
}