.jf-icon-avatar {
  display: grid;
  .jf-live-label {
    bottom: auto;
    top: -7px;
  }
  .jf-avatar {
    border: 1px solid colors(jf-white);
    border-radius: 50%;
  }
  &-check-btn {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0;
    border: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    z-index: 1;
    cursor: pointer;
  }
}